// extracted by mini-css-extract-plugin
export var opShow = "dvrw-t";
export var opHidden = "dvrw-u";
export var op2 = "dvrw-v";
export var op4 = "dvrw-w";
export var op6 = "dvrw-x";
export var op8 = "dvrw-y";
export var op9 = "dvrw-z";
export var hero = "dvrw-A";
export var heroHeadline = "dvrw-B";
export var COLOR = "dvrw-C";
export var heroBrand = "dvrw-D";