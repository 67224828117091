import * as React from "react"
import * as heroStyle from '../styles/hero.module.scss';
import { useEffect, useState } from 'react';
// markup
const Hero = () => {

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, []);

  const style = {
    opacity: 1 - (offset*0.002),
  }

  return (
      <div className={heroStyle.hero}>
        <div className="container-sm mb-5">
            <div id="hero-content" style={style} className={heroStyle.heroHeadline}>
              <h1>
                Webentwicklung <span>Unkompliziert.</span>
                <br />
                Umsatz steigern mit stressfreien Lösungen.
              </h1>
<div className="row pt-5">
  <div className="col-12 col-md-4 mb-3 mb-md-0"><h2>Einfache Lösungen die inspirieren</h2><p>Rundum-sorglose-Lösungen zur Verwirklichung deiner Ideen.</p></div>
  <div className="col-12 col-md-4 mb-3 mb-md-0"><h2>Unverwechselbar
    smart</h2><p>Webapps, Websites, Onlineshops. Smart, schön und einfach.</p></div>
  <div className="col-12 col-md-4 mb-3 mb-md-0"><h2>Wachstum fördern</h2><p>Wir fördern Wachstum indem wir zu hören und verstehen</p></div>
</div>
            </div>
        </div>{/* /.container */}
      </div>
  )
}

export default Hero
