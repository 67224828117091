// extracted by mini-css-extract-plugin
export var carousel = "dvrw-T";
export var pointerEvent = "dvrw-U";
export var carouselInner = "dvrw-V";
export var carouselItem = "dvrw-W";
export var active = "dvrw-X";
export var carouselItemNext = "dvrw-Y";
export var carouselItemPrev = "dvrw-Z";
export var carouselItemStart = "dvrw-_";
export var carouselItemEnd = "dvrw--";
export var carouselFade = "dvrw-0";
export var carouselControlPrev = "dvrw-1";
export var carouselControlNext = "dvrw-2";
export var carouselControlPrevIcon = "dvrw-3";
export var carouselControlNextIcon = "dvrw-4";
export var carouselIndicators = "dvrw-5";
export var carouselCaption = "dvrw-6";
export var carouselDark = "dvrw-7";
export var opShow = "dvrw-8";
export var opHidden = "dvrw-9";
export var op2 = "dvrw-aa";
export var op4 = "dvrw-ba";
export var op6 = "dvrw-ca";
export var op8 = "dvrw-da";
export var op9 = "dvrw-ea";
export var process = "dvrw-fa";
export var caption = "dvrw-ga";