import * as React from "react"
import * as marqueeImageStyle from '../styles/marquee.images.module.scss';

const AnimatedImage = ({dataShowcaseFirst, dataShowcaseSecond, dataShowcaseThird, reverse}) => {

  const getNumber = (function() {
    let previous = NaN;
    return function() {
      const min = 0;
      const max = 12 + (!isNaN(previous) ? -1 : 0);
      let value = Math.floor(Math.random() * (max - min + 1)) + min;
      if (value === previous) {
        value += 1;
      }
      previous = value;
      return value;
    };
  })();

  const imageListFirst = () => (
        <div className={marqueeImageStyle.marqueeImageList}>
          {dataShowcaseFirst.map(function(obj, i){
            return (
                <div key={`first-${i}`} className={`${marqueeImageStyle.card} order-${i+Math.floor(getNumber())}`}><div className={marqueeImageStyle.cardBody}><img src={obj.image} alt=""/></div></div>
            );
          })}
        </div>
  )

  const imageListSecond = () => (
    <div className={marqueeImageStyle.marqueeImageList}>
      {dataShowcaseSecond.map(function(obj, i){
        return (
            <div key={`second-${i}`} className={`${marqueeImageStyle.card} order-${i+Math.floor(getNumber())}`}><div className={marqueeImageStyle.cardBody}><img src={obj.image} alt=""/></div></div>
        );
      })}
    </div>
  )

  const imageListThird = () => (
    <div className={marqueeImageStyle.marqueeImageList}>
      {dataShowcaseThird.map(function(obj, i){
        return (
            <div key={`third-${i}`} className={`${marqueeImageStyle.card} order-${i+Math.floor(getNumber())}`}><div className={marqueeImageStyle.cardBody}><img src={obj.image} alt=""/></div></div>
        );
      })}
    </div>
  )


  return (
      <div className={marqueeImageStyle.hero}>
      <div className="container-fluid px-0 overflow-hidden">
        <div className="position-relative overflow-hidden">
      <div
          className={marqueeImageStyle.marqueeImage}>
        <div className={marqueeImageStyle.marqueeImageInner} aria-hidden="true">
          {imageListFirst()}
          {imageListSecond()}
          {imageListThird()}
          {imageListFirst()}
          {imageListSecond()}
          {imageListFirst()}
          {imageListSecond()}
        </div>
      </div>
      </div>
      </div>
      </div>
  )
}

export default AnimatedImage;
