// extracted by mini-css-extract-plugin
export var opShow = "dvrw-a";
export var opHidden = "dvrw-b";
export var op2 = "dvrw-c";
export var op4 = "dvrw-d";
export var op6 = "dvrw-e";
export var op8 = "dvrw-f";
export var op9 = "dvrw-g";
export var contact = "dvrw-h";
export var BACKGROUND = "dvrw-i";
export var contactButton = "dvrw-j";