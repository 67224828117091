import React from 'react';
import * as testimonialsStyle from '../styles/testimonials.module.scss'
import marks from '../images/marks.svg';
import fire from '../images/fire.svg';

// data
const data = [
  {
    headline: "Maregha",
    name: "Corinna / Gründerin",
    description:
        "Eine sehr gute und sehr angenehme Zusammenarbeit. Super Kommunikation und ein tolles kreatives Arbeiten. Ich habe dadurch auch persönlich viel gelernt und bin unheimlich zufrieden und glücklich mit der Arbeit. Besonders hervorzuheben ist der individuelle Support. Man wird von Anfang  an und auch über die abgeschlossene Arbeit hinaus unterstützt, bekommt jederzeit Hilfe und Tipps...",
  },
  {
    headline: "Sound of Music",
    name: "Claudine / CEO",
    description:
        "Professionelle Arbeit von A-Z. Moghancy hat uns eine komplett neue Onlinepräsenz geschaffen und smarte Möglichkeiten aufgezeigt. Die Rundum-Betreuung für Online-Shop und Webdesign wird hier auf ein ganz neues, anspruchsvolles Niveau gehoben. Von Beratung, kreativen Implementationsmöglichkeiten, Ästhetik, Funktionalität bis zur Nachbetreuung...",
  },
  {
    headline: "Rücken Athlet",
    name: "Ramin / CEO",
    description:
        "Alles hat reibungslos und schnell funktioniert. Bei der Konzeptionierung wurde gut mitgedacht und bei der Umsetzung stets Rücksprache gehalten. Besonders die Erreichbarkeit und Organisation haben hervorgestochen.",
  },
  {
    headline: "Lowheels",
    name: "David / CEO",
    description:
        "Kann ich nur Empfehlen! Wir haben eine neue Homepage gebraucht und es lief einwandfrei. Auch danach wurden uns noch alle Fragen beantwortet und Hilfe bei jedem Problem geleistet.",
  },
  {
    headline: "Karo Kauer Label",
    name: "Julia / E-COMMERCE MANAGER",
    description:
        "Ich arbeite schon über 2 Jahre mit Moghancy zusammen und kann sagen, dass ich immer zu 110% zufrieden bin. Das Team von Moghancy denkt einfach mit, ist gut erreichbar, arbeitet strategisch, sauber und schnell. Moghancy handelt immer lösungsorientiert und im Sinne des Kunden.",
  },
  {
    headline: "Emil Enz",
    name: "EMMI / CEO",
    description:
        "Auch für Laien wird alles so erklärt, dass man es verstehen und nachvollziehen kann. Die individuelle und persönliche Beratung ist grandios - sehr zu empfehlen. 1A - Danke!",
  },
]

// markup
const Testimonials = () => {
  return (
      <div className={testimonialsStyle.process}>
            <div id="carouselExampleCaptions" className={testimonialsStyle.carousel + ' carousel slide'} data-bs-ride="carousel">
              <div className="container-sm">
                <h2 className={'mb-4'}>GANZ VIEL LIEBE<img className="spin" src={fire} alt="/"/></h2>
                <img src={marks} className="marks position-absolute" alt=""/>
              <div className="row">
             <div className="col-12 mb-4">
               <div className={'carousel-indicators position-relative'}>
                 {data.map(function(obj, i){
                   return <button type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide-to={i} className={i === 0
    ? 'active'
    : ''} key={`slide-${i + 1}`} aria-current="true" aria-label={`Slide ${i + 1}`}/>; })}
               </div>
             </div>{/* /.col-6 */}
              <div className="col-12">
                <div className={'carousel-inner mx-auto'}>
                    {data.map(function(obj, i){
                      return <div key={`carousel-${i + 1}`} className={`carousel-item ${ i=== 0 ? 'active' : ''}`}>
                        <h3>{obj.headline}</h3>
                        <p>{obj.name}</p>
                        <hr/><br/>
                        <p className={testimonialsStyle.caption}>{obj.description}</p>
                      </div>;
                    })}
                </div>{/* /.carousel-inner */}
              </div>{/* /.col-6 */}
              </div>{/* /.row */}
              </div>{/* /.container-fluid */}
            </div>{/* /.carousel */}

      </div>
  )
}

export default Testimonials
