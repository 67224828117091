import React from 'react';
import * as contactStyle from '../styles/contact.module.scss'

// markup
const Contact = () => {
  return (
      <div className={contactStyle.contact}>
        <div className="container">
          <h2>Wir überarbeiten momentan unsere Webseite. Doch das hält uns nicht davon ab mit dir zu plauschen.</h2>
          <div className={`${contactStyle.contactButton} d-flex align-items-center justify-content-center`}>
            <div><a href={'mailto:info@moghancy.com'} className={'mail-contact btn btn-primary'}> Kontakt </a></div>
          </div>
          </div>
      </div>
  )
}

export default Contact;
