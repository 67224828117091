import React, {useEffect} from "react";
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import AnimatedImage from '../components/AnimatedImage';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import {dataShowcaseFirst, dataShowcaseSecond, dataShowcaseThird} from '../components/showcase.data';

// markup
const IndexPage = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  },[])


  return (
    <>
      <Navbar/>
      <Hero />
      <AnimatedImage dataShowcaseFirst={dataShowcaseFirst} dataShowcaseThird={dataShowcaseThird} dataShowcaseSecond={dataShowcaseSecond} reverse={false} />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  )
}

export default IndexPage
