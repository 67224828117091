function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
}
const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

export const dataShowcaseFirst = [
  {
    image: images['UI1.jpg'].default,
    text: "",
  },
  {
    image: images['UI2.jpg'].default,
    text: "",
  },
  {
    image: images['UI3.jpg'].default,
    text: "",
  },
  {
    image: images['UI4.jpg'].default,
    text: "",
  },
  {
    image: images['UI5.jpg'].default,
    text: "",
  },
  {
    image: images['UI6.jpg'].default,
    text: "",
  },
  {
    image: images['UI7.jpg'].default,
    text: "",
  },
  {
    image: images['UI5.jpg'].default,
    text: "",
  },
  {
    image: images['UI6.jpg'].default,
    text: "",
  },
  {
    image: images['UI7.jpg'].default,
    text: "",
  },
]

export const dataShowcaseSecond = [
  {
    image: images['UI8.jpg'].default,
    text: "lamborghini",
  },
  {
    image: images['UI9.jpg'].default,
    text: "marvel",
  },
  {
    image: images['UI10.jpg'].default,
    text: "puma",
  },
  {
    image: images['UI11.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI12.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI13.jpg'].default,
    text: "marvel",
  },
  {
    image: images['UI14.jpg'].default,
    text: "marvel",
  },
  {
    image: images['UI12.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI13.jpg'].default,
    text: "marvel",
  },
  {
    image: images['UI14.jpg'].default,
    text: "marvel",
  },
]

export const dataShowcaseThird = [
  {
    image: images['UI14.jpg'].default,
    text: "puma",
  },
  {
    image: images['UI15.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI16.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI17.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI18.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI19.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI20.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI18.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI19.jpg'].default,
    text: "nike",
  },
  {
    image: images['UI20.jpg'].default,
    text: "nike",
  },
]
